import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from '../../app-config/app-config.service';

@Injectable()
export class InsightsService {
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      connectionString:
        this.appConfigService?.config?.appInsightsConnectionString,
      extensions: [this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
    },
  });

  constructor(
    private router: Router,
    private appConfigService: AppConfigService
  ) {
    this.appInsights.loadAppInsights();
  }

  trackEvent(name: string): void {
    this.appInsights.trackEvent({ name });
  }

  trackTrace(message: string): void {
    this.appInsights.trackTrace({ message });
  }

  setAuthenticatedUserContext(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearAuthenticatedUserContext() {
    this.appInsights.clearAuthenticatedUserContext();
  }
}
